/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-6nt9s9 pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/479cc70ee82441c5a580f53f6b6a978f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/479cc70ee82441c5a580f53f6b6a978f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/479cc70ee82441c5a580f53f6b6a978f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/479cc70ee82441c5a580f53f6b6a978f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/479cc70ee82441c5a580f53f6b6a978f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/479cc70ee82441c5a580f53f6b6a978f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/479cc70ee82441c5a580f53f6b6a978f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/479cc70ee82441c5a580f53f6b6a978f_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" alt={""} src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Text className="swp-T00-mobile text-box text-box--right mt--02" style={{"maxWidth":497,"marginTop":null}} content={"[[T00Text01]]"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":488}} content={"[[T00Text02]]"}>
              </Text>

              <Title className="title-box fs--30 mt--06" style={{"maxWidth":620,"marginTop":null}} content={"<br><span style=\"font-weight: 700;\"><br><br></span><br>"}>
              </Title>

              <Title className="title-box" content={"<span style=\"font-weight: bold; font-style: italic;\">Myslí si o nás a řekli o nás</span>"}>
              </Title>

              <Text className="text-box text-box--right mt--02" style={{"maxWidth":429,"marginTop":null}} content={"[[T00Text05]]"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" layout={"l1"} name={"llcoyibk71o"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={""}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box lh--2" style={{"maxWidth":270}} content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}